import axios from 'axios';
import router from './router/index.js';
import {env} from './config/constants.js';

const BASE_URL = env.API_BASE_URL;

// axios.defaults.withCredentials = true;
axios.interceptors.response.use((response) => {
   let isRedirect = response.request.responseURL != response.config.url;
   if (isRedirect) {
      console.log('redirection captured by axios');
      window.location = response.request.responseURL;
      return;
   }
   return response;
}, (error) => {
   console.log('error', error);
   return Promise.reject(error)
});

function call(url, method, data = {}) {
   return axios({
      method,
      url: BASE_URL + url,
      data,
      headers: {
         Authorization: `Bearer ${window.localStorage.getItem("token")}`,
         'Access-Control-Allow-Origin': '*',
      }
   })
   .then(response => {
      return response.data;
   })
   .catch(error => {
      if (error.response.status == 401) {
         console.log('Unauthorized api request');
         // store.commit(types.LOGOUT);
         router.replace('/');
      }
      return Promise.reject(error);
    });
}

function get(url) {
   return call(url, 'get');
}

function post(url, data) {
   return call(url, 'post', data);
}

function put(url, data) {
   return call(url, 'put', data);
}

function destroy(url) {
   return call(url, 'delete');
}

export default {
   get,
   post,
   put,
   destroy
};