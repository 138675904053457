<template>
   <section class="p-success">
      <div class="asuro-webapp__content">
         <div class="pe-user-name h1 bg-keyvisual">
            {{ fullName }}
         </div>

         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <div class="h1">
                     Herzlichen Glückwunsch
                  </div>

                  <p>
                     Die Cyberschutz-Versicherung ist nun aktiviert.
                  </p>
               </div>

               <div class="col-sm-12">
                  <div class="pe-added-insurance">
                     <div class="pe-added-insurance__logo-wrap">
                        <img 
                           src="https://media.asuro.de/insurer/icons/icon_46.svg"
                           class="pe-added-insurance__logo"
                           alt="Cyberschutzversicherung">
                     </div>

                     <div class="pe-added-insurance__content">
                        <div class="pe-added-insurance__header">
                           Cleversurance
                        </div>

                        <div class="pe-added-insurance__name">
                           Cyberschutzversicherung
                        </div>

                        <div class="pe-added-insurance__clarification">
                           kostenfrei bis {{ freeOfChargeUntilDate }}
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-sm-12">
                  <p class="color-warning">
                     Tipp: Ihr Versicherungsmanager kann mehr!
                  </p>

                  <List :items="listItems" />
               </div>
            </div>
         </div>

         <div class="pe-asuro-stripe">
            <img
               class="pe-asuro-stripe__logo" 
               src="./../../public/asuro_logo_white.svg" alt="asuro"
            >
         </div>
      </div>

      <div class="asuro-webapp__footer">
         <b-overlay 
            :show="isButtonSpinnerVisible" 
            spinner-medium
            opacity="0.2"
         >
            <a
               :href="insuranceManagerURL"
               class="btn btn-green is-full"
               :class="{ 'disabled' : !canNavigateToInsuranceManager }"
            >
               Versicherungsmanager
            </a>
         </b-overlay>
      </div>
   </section>
</template>

<script>
   import moment from 'moment';
   // @ is an alias to /src
   import List from '@/components/List.vue';
   import { ASURO_WEB_INSURANCE_MANAGER_URL } from '../config/constants.js';

   export default {
      name: 'Consent',
      components: {
         List,
      },
      data() {
         return {
            listItems: [
               { text:"Alle Versicherungen im Überblick", icon: 'icon_savemoney_darkblue.svg' },
               { text:"Sparpotenzial sofort erkennen", icon: 'icon_savemoney_darkblue.svg'},
               { text:"Wir unterstützen Sie im Schadensfall", icon: 'icon_savemoney_darkblue.svg'},
            ],
            customer: JSON.parse(localStorage.getItem('customer')),
            isButtonSpinnerVisible: false,
         }
      },
      computed: {
         canNavigateToInsuranceManager() {
            return this.customer.hasStoredSignature;
         },
         freeOfChargeUntilDate() {
            return moment().add(3, 'months').format('DD.MM.YYYY');
         },
         fullName() {
            return `${this.customer.firstname} ${this.customer.lastname}`;
         },
         insuranceManagerURL() {
            return `${ASURO_WEB_INSURANCE_MANAGER_URL}?ssoToken=${this.customer.ssoToken}&email=${this.customer.email}`;
         }
      },
   };
</script>

<style lang="less">
   .p-success {
      padding-top: 0 !important;
      padding-bottom: 20px;
      text-align: center;

      .pe-user-name {
         padding: 20px 0;
      }

      .b-list {
         display: inline-block;
      }

      .pe-added-insurance {
         border: solid 1px @as-lighter-grey;
         text-align: left;
         margin-bottom: 20px;
         .flex-row-left-center;
         .flex-display(inline-flex);
         .align-items(stretch);

         &__logo-wrap {
            background-color: @as-lighter-grey;
            padding: 0 20px;
            .flex-col-center;
         }

         &__logo {
            border: solid 10px white;
            width: calc(@insurer-icon-width + 20px);
            height: calc(@insurer-icon-height + 20px);
            border-radius: 100%;
         }

         &__header {
            text-transform: uppercase;
         }

         &__content {
            padding: 10px 20px;
            .flex-grow(1);
         }

         &__name {
            font-size: @as-font-size-x-large;
            line-height: 1.2;
            font-weight: 900;
            color: @as-dark-blue;
            margin-bottom: 6px;
            word-break: break-word;
         }

         &__clarification {
             color: @as-green;
         }
      }

      .pe-asuro-stripe {
         background-color: @as-middle-grey;
         padding: 10px;
         text-align: center;
         margin: 10px 0 20px 0;

         &__logo {
            height: 30px;
            width: auto;
         }
      }
   }
</style>