<template>
   <section class="p-consent">
      <div class="asuro-webapp__content">
         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <div class="h1">
                     Aktivierung abschließen
                  </div>

                  <div class="h3">
                     Zur Aktivierung der Cyberschutz-Versicherung benötigt unser Partner asuro Ihre Zustimmung.
                  </div>
               </div>

               <div class="col-sm-12">
                  <List :items="listItems" />
               </div>

               <transition name="fade">
               <div  v-if="!customer.hasStoredSignature" class="col-sm-12">
                  <div class="pe-signature">
                     <vueSignature
                        ref="signature"
                        :sigOption="option"
                        @touchstart.native="isSignaturePlaceholderVisible = false"
                        @mousedown.native="isSignaturePlaceholderVisible = false"
                     />

                     <div
                        class="pe-signature__placeholder"
                        v-if="isSignaturePlaceholderVisible"
                     >
                        Unterschrift
                     </div>

                     <div class="pe-signature__line"></div>

                     <a
                        href="javascript:void(0);"
                        class="btn pe-signature__clear"
                        @click="clear"
                     >
                        Korrigieren
                     </a>

                     <div class="pe-signature__clarification">
                        Diese Unterschrift kann von Ihrer normalen Unterschrift abweichen.
                     </div>
                  </div>
               </div>
               </transition>

               <div class="col-sm-12">
                  <CheckboxCustom
                     id="consentCheck"
                     v-model="isConsentChecked"
                     @checkbox-clicked="setConsentChecked"
                  >
                     Hiermit wird meine Cyberschutz Versicherung mit dem asuro Maklerservice aktiviert
                  </CheckboxCustom>
               </div>
            </div>
         </div>

         <div class="container pe-consent-small-text">
            <div class="row">
               <div class="col-sm-12">
                  Hiermit akzeptiere ich die <a href="javascript:void(0);">Versicherungsbedingungen</a> der CLEVERSURANCE Cyberschutzpolice mit angegebenen <a href="javascript:void(0);">Tarifmerkmalen</a> und den <a href="javascript:void(0);">Maklerauftrag</a>. Der asuro Versicherungsmanager bleibt für Sie 100% kostenlos! Mehr dazu in unseren <a href="javascript:void(0);">AGB</a>.
               </div>
            </div>
         </div>

         <LogosStripe />
      </div>

      <div class="asuro-webapp__footer">
         <b-overlay
            :show="isButtonSpinnerVisible"
            spinner-medium
            opacity="0.2"
         >
            <a
               href="javascript:void(0);"
               class="btn btn-green is-full"
               :class="{ 'disabled' : !isReadyForSubmit }"
               @click="submit()"
            >
               Jetzt Cyberschutz aktivieren
            </a>
         </b-overlay>
      </div>
   </section>
</template>

<script>
   import vueSignature from "vue-signature";

   import api from '../services/asuroApiService.js';
   import { CYBER_INSURANCE_ID } from '../config/constants.js';

   import List from '@/components/List.vue';
   import LogosStripe from '@/components/LogosStripe.vue';
   import CheckboxCustom from '@/components/CheckboxCustom.vue';

   export default {
      name: 'Consent',
      components: {
         CheckboxCustom,
         List,
         LogosStripe,
         vueSignature,
      },
      data() {
         return {
            listItems: [
               { text:"Garantiert 100% kostenlos" },
               { text:"Vertrag endet automatisch"},
               { text:"Jederzeit widerrufbar"},
            ],
            isConsentChecked: false,
            isSignaturePlaceholderVisible: true,
            option:{
               penColor:"rgb(0, 0, 0)",
               backgroundColor:"rgb(255,255,255)"
            },
            customer: { hasStoredSignature: false } ,
            isButtonSpinnerVisible: false,
         }
      },
      computed: {
         isReadyForSubmit() {
            return this.isConsentChecked &&
               (!this.isSignaturePlaceholderVisible || this.customer.hasStoredSignature);
         },
         isSignatureEmpty() {
            return this.$refs.signature ? this.$refs.signature.isEmpty() : false;
         }
      },
      methods: {
         clear(){
            this.$refs.signature.clear();
            this.isSignaturePlaceholderVisible = true;
         },
         fetchCustomer() {
            const customer = {
               ssoToken: this.$route.query.token,
            };

            return api.fetchCustomer(customer)
               .then((response) => {
                  this.customer = response;
                  localStorage.setItem('customer', JSON.stringify(this.customer));
               })
               .catch(() => {
                  this.$router.push('/error');
               });
         },
         setConsentChecked(value){
            this.isConsentChecked = value;
         },
         storeSignature() {
            const signaturePng = this.$refs.signature.save();

            return api.storeSignature(this.customer, signaturePng)
               .then(() => this.fetchCustomer());
         },
         submit() {
            if (!this.isReadyForSubmit) {
               var element = document.getElementById("consentCheck");
               element.scrollIntoView({ behavior: "smooth" });
               return;
            }

            this.isButtonSpinnerVisible = true;

            if (this.customer.hasStoredSignature) {
               this.submitInsuranceRequest();
               return;
            }

            this.storeSignature().then(() => this.submitInsuranceRequest());
         },
         submitInsuranceRequest() {
            return api.requestInsurance(this.customer, CYBER_INSURANCE_ID)
               .then(() => this.$router.push('/success'));
         }
      },
      created() {
         this.fetchCustomer();
      }
   };
</script>

<style lang="less">
   .p-consent {
      padding-top: var(--page-top-padding) !important;
      padding-bottom: 20px;
      text-align: center;

      .b-list {
         display: inline-block;
      }

      .pe-signature {
         --canvas-height: 120px;

         position: relative;
         .flex-col-center;

         canvas { // signature pad
            height: var(--canvas-height) !important;
         }

         &__placeholder {
             color: @as-dark-grey;
             pointer-events: none;
             position: absolute;
             top: calc(var(--canvas-height) - 20px);
             left: 50%;
             .transform(translateX(-50%));
         }

         &__line {
            border-bottom: 1px solid @as-middle-grey;
            margin-bottom: 15px;
         }

         &__clear,
         &__clear:hover,
         &__clear:active,
         &__clear:focus {
            border: solid 1px @as-darker-blue;
            color: @as-darker-blue;
            background-color: transparent;
            align-self: center;
            display: block;
         }

         &__clarification {
            margin: 10px 0 40px 0;
            font-size: @as-font-size-x-small;
            line-height: 1.2;
         }
      }

      .pe-consent-small-text {
         background-color: @as-lighter-grey;
         padding: 20px;
         font-size: @as-font-size-x-small;
         text-align: left;
      }
   }
   .fade-enter-active, .fade-leave-active {
     transition: opacity .5s;
   }
   .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
     opacity: 0;
   }
</style>

