<template>
   <section class="p-error">
      <div class="container">
         <div class="row">
            <div class="col-sm-12">
               <div class="pe-error">
                  <img class="pe-error__image" src="./../../public/warning.svg" alt="error">

                  <div class="h1">Es scheint ein Fehler aufgetreten zu sein</div>
                  Bitte versuchen Sie es erneut

                  <a
                     href="javascript:void(0);"
                     class="btn btn-green is-full"
                     @click="$router.push('/')"
                  >
                     Zurück zum Start
                  </a>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   export default {
      name: 'Error',
   };
</script>

<style lang="less">
   .p-error {
      padding-top: var(--page-top-padding) !important;

      .pe-error {
         text-align: center;
         .flex-col-center;
         .align-items(center);

         &__image {
             width: 60px;
             height: auto;
             margin-bottom: 20px;
         }

         .btn-green {
            margin-top: 20px;
            width: 100%;
         }
      }
   }
</style>

