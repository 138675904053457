import api from '../api.js';
import UAParser from 'ua-parser-js';


function fetchCustomer(customer) {
   return api.get(`v9/customers/${customer.ssoToken}`);
}

function requestInsurance(customer, insurerId) {
   const device = getDevice();
   const insurerRequest = { insurerId: insurerId, customerId: customer.id};
   const payload = { insurerRequest, device};

   return api.post(`v9/users/${customer.id}/insurerrequests`, payload);
}

function storeSignature(customer, signature) {
   const payload = { signature: signature, customerId: customer.id };
   return api.post(`v9/users/${customer.id}/updateSignature`, payload);

}

function getDevice() {
   const parser = new UAParser();
   const browser = parser.getBrowser();
   const deviceInfo = parser.getDevice();

   const device = {
      uuid: localStorage.getItem('uuid'),
      model: deviceInfo.model,
      version: browser.version,
      manufacturer: browser.name,
      platform: deviceInfo.vendor,
   };

   return device;
}

export default {
   fetchCustomer,
   requestInsurance,
   storeSignature,
};