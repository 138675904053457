<template>
   <label class="b-checkbox-custom">
      <div class="b-checkbox-custom__text">
         <slot></slot>
      </div>

      <!-- hidden input -->
      <input 
         type="checkbox" 
         :checked="value" 
         ref="checkbox" 
         @change="onChange()"
      >

      <!-- styled input -->
      <span class="b-checkbox-custom__checkbox"></span>
   </label>
</template>

<script>
   export default {
      data() {
         return {
            checked: this.value,
         }
      },
      props: {
         value: {}
      },
      methods: {
         onChange: function() {
            this.$emit('checkbox-clicked', this.$refs.checkbox.checked);
         }
      },
   }
</script>

<style lang="less">
   .b-checkbox-custom {
      --checkbox-size: 25px;

      position: relative;
      padding-left: calc(var(--checkbox-size) * 1.5);
      cursor: pointer;
      .flex-row-left-center;
      .align-items(flex-start);

      &__text {
         color: @as-darker-grey;;
         text-align: left;
         font-size: @as-font-size-regular;
         font-weight: 400;
         line-height: 1.3;
      }

      input { // Hide the browser's default checkbox
         position: absolute;
         opacity: 0;
         cursor: pointer;
         height: 0;
         width: 0;
      }

      &__checkbox { // checkbox
         position: absolute;
         top: 4px;
         left: 0;
         height: var(--checkbox-size);
         width: var(--checkbox-size);
         background-color: white;
         border: solid 1px @as-middle-grey;

         &:after { // checkbox tick
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 3px;
            width: 8px;
            height: 13px;
            border: solid @as-green;
            border-width: 0 3px 3px 0;
            .transform(rotate(45deg));
         }
      }

      input:checked ~ .b-checkbox-custom__checkbox { // checked checkbox
         &:after { // show tick
            display: block;
         }
      }
   }
</style>
